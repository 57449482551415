import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  BREAKPOINTS_ENUM,
  BreakpointServerType,
  breakpoints,
} from '@/common/constants/breakpoints';
import { useDebounceCallback } from '../use-debounce';

const defaultValue = {};
const BreakpointContext = createContext(defaultValue);

const getBreakpoint = (window: Window): BREAKPOINTS_ENUM => {
  if (window.matchMedia(`(min-width: ${breakpoints.xl})`).matches) {
    return BREAKPOINTS_ENUM.XL;
  }
  if (window.matchMedia(`(min-width: ${breakpoints.lg})`).matches) {
    return BREAKPOINTS_ENUM.LG;
  }
  if (window.matchMedia(`(min-width: ${breakpoints.md})`).matches) {
    return BREAKPOINTS_ENUM.MD;
  }
  if (window.matchMedia(`(min-width: ${breakpoints.sm})`).matches) {
    return BREAKPOINTS_ENUM.SM;
  }
  if (window.matchMedia(`(min-width: 0em)`).matches) {
    return BREAKPOINTS_ENUM.XS;
  }

  // can not match with any matchMedia (that would be a problem)
  return BREAKPOINTS_ENUM.XL;
};

export type BreakpointProviderProps = {
  children: ReactNode;
  // on the server-side Request we use the user agent to create a best educated guess for its Viewport
  // using user agent and library which will only be one of these three possible Viewports
  initialBreakpoint?: BreakpointServerType;
};

const BreakpointProvider = ({
  children,
  initialBreakpoint,
}: BreakpointProviderProps) => {
  const [breakpoint, setBreakpoint] = useState<BREAKPOINTS_ENUM>(
    initialBreakpoint || BREAKPOINTS_ENUM.SM,
  );

  const debouncedHandleResize = useDebounceCallback(() => {
    const newViewport = getBreakpoint(window);
    setBreakpoint(newViewport);
  }, 100);

  useEffect(() => {
    const currentBreakpoint = getBreakpoint(window);
    setBreakpoint(currentBreakpoint);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      debouncedHandleResize.cancel();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [breakpoint, debouncedHandleResize]);

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpointDevice() {
  const context = useContext(BreakpointContext);

  const isDesktop =
    context === BREAKPOINTS_ENUM.LG ||
    context === BREAKPOINTS_ENUM.XL ||
    context === BREAKPOINTS_ENUM['2XL'];

  const isMobile =
    context === BREAKPOINTS_ENUM.XS ||
    context === BREAKPOINTS_ENUM.SM ||
    context === BREAKPOINTS_ENUM.MD;

  return {
    isDesktop,
    isMobile,
  };
}

function useBreakpoint() {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error('useBreakpoint is not used within a BreakpointProvider');
  }
  return context;
}

export {
  /** can be used on any component level to get the latest Viewport */
  useBreakpoint,
  /** should only be set at application level */
  BreakpointProvider,
  /** never consume this directly, only exported for our mocked provider, see our mocks! */
  BreakpointContext,
  useBreakpointDevice,
};
