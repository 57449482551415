import Script from 'next/script';

const OneScript = () => {
  return (
    <Script
      id="onepixel-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        var i=new Image,u="https://s3-sa-east-1.amazonaws.com/frame-image-br/bg.png?x-id=ded01183b654a8aaba6f7175ac4954e7&x-r="+document.referrer+"&x-s="+window.location.href;i.src=u;`,
      }}
    />
  );
};

export default OneScript;
