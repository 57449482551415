import { CorporateContactJsonLd } from 'next-seo';

const CorporateContactJson = () => {
  return (
    <CorporateContactJsonLd
      url="https://socialcommerce.centauro.com.br"
      logo="/images/meta/gruposbf-logo.png"
      contactPoint={[
        {
          telephone: '+55 (11) 4004-8008',
          contactType: 'Fale conosco',
          areaServed: 'BR',
          availableLanguage: ['Brazilian Portuguese'],
        },
      ]}
    />
  );
};

export default CorporateContactJson;
