import getConfig from 'next/config';

type PublicRuntimeConfig = {
  gaID: string;
  gtmID: string;
  termsDate: string;
  platformDNS: string;
  publicApi: {
    base: string;
  };
  keycloak: {
    clientId: string;
    clientSecret: string;
    wellKnown: string;
    baseUrl: string;
    redirectLogout: string;
  };
  telegram: {
    nike: {
      name: string;
      link: string;
    };
    centauro: {
      name: string;
      link: string;
    };
  };
};
type NextConfig = {
  publicRuntimeConfig: PublicRuntimeConfig;
};

export const getCoreConfig = (): NextConfig => {
  const config = getConfig();
  return { ...config };
};
