import { ReactNode } from 'react';
import styled from 'styled-components';
import Container from '@/common/components/Container';
import { MAIN_CONTENT_MAX_WIDTH } from './constants/layout';

type MainLayoutPropsTypes = {
  children: ReactNode;
  fullHeight?: boolean;
  maxWidth?: string;
};

const LayoutContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-height: 100vh;
`;

const MainLayout = ({
  fullHeight = true,
  children,
  maxWidth = MAIN_CONTENT_MAX_WIDTH,
}: MainLayoutPropsTypes) => {
  return (
    <LayoutContainer>
      <Container fullHeight={fullHeight} maxWidth={maxWidth}>
        {children}
      </Container>
    </LayoutContainer>
  );
};

export default MainLayout;
