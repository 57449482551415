export const LOCAL_STORAGE_USER = 'current-user';
export const LOCAL_STORAGE_ACCESS_TOKEN = 'access-token';
export const LOCAL_STORAGE_USER_NOTIFICATIONS = 'user-notifications';
export const LOCAL_STORAGE_USER_INDICATORS = 'user-indicators';

export const COOKIE_CLIENT_TOKEN = 'x_client_token';
export const COOKIE_ACCESS_TOKEN = 'x_access_token';
export const COOKIE_REFRESH_TOKEN = 'x_refresh_token';

export const UNAUTHORIZED_EXCEPTION_CODE = 401;
