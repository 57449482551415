import { DefaultSeo } from 'next-seo';

const InitialSeo = () => {
  return (
    <DefaultSeo
      defaultTitle="SocialCommerce - GrupoSBF"
      facebook={{
        appId: 'xxxxxxxx',
      }}
      openGraph={{
        type: 'website',
        locale: 'pt_BR',
        site_name: 'socialcommerce.centauro.com.br',
      }}
      twitter={{
        site: '@gruposbf',
        handle: '@gruposbf',
        cardType: 'summary',
      }}
      additionalMetaTags={[
        {
          property: 'google-site-verification',
          content: 'xxxxxxxxxxxx',
        },
        {
          name: 'msapplication-TileColor',
          content: '#ffffff',
        },
        {
          name: 'msapplication-TileImage',
          content: '/images/meta/ms-icon-144x144.png',
        },
        {
          name: 'msapplication-config',
          content: '/images/meta/browserconfig.xml',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
      ]}
    />
  );
};

export default InitialSeo;
