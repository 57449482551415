import { createGlobalStyle } from 'styled-components';
import swiperCSS from 'swiper/swiper.css';

export const GlobalStyle = createGlobalStyle`
  ${swiperCSS}

  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif, arial;
  }

  h1, h2, h3, h4 {
    font-family: 'Work Sans', sans-serif, arial;
  }
`;

export default GlobalStyle;
