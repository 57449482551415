import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_USER,
} from '@/common/config';
import { BusinessUnits } from '@/common/constants/BusinessUnits';
import { CurrentUser } from '@/common/types';
import {
  WebsocketEventPayload,
  WebsocketEvents,
} from '@/common/types/WebsocketEvent.types';
import {
  getStorageItem,
  setStorageItem,
  removeStorageItem,
} from '@/common/utils/localStorage';
import { socket } from '@/common/utils/socket/client';

interface AuthProviderProps {
  children: ReactNode;
  storybookUser?: CurrentUser | boolean; // only for test purposes
}

interface AuthContextData {
  signed: boolean;
  token: string;
  buArray: BusinessUnits[];
  user: CurrentUser;
  unreadNotifications: boolean;
  isEmailFlagReady: MutableRefObject<boolean>;
  isCookieIdFlagReady: MutableRefObject<boolean>;
  setUnreadNotifications: (unreadNotifications: boolean) => void;
  updateUser: (user: CurrentUser) => void;
  signIn: (user: CurrentUser, token: string) => void;
  signOut: () => void;
}

const userFromStorage = getStorageItem<CurrentUser>(LOCAL_STORAGE_USER);
const tokenFromStorage = getStorageItem<string>(LOCAL_STORAGE_ACCESS_TOKEN);
const defaultUser = {} as CurrentUser;

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState({} as CurrentUser);
  const isEmailFlagReady = useRef(false);
  const isCookieIdFlagReady = useRef(false);
  const [unreadNotifications, setUnreadNotifications] =
    useState<boolean>(false);

  const [token, setToken] = useState('');

  useEffect(() => {
    setUser(userFromStorage || defaultUser);
    setToken(tokenFromStorage || '');

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleWebsocketEvents = (event: WebsocketEventPayload<boolean>) => {
    if (event.type === WebsocketEvents.NotificationsUnread)
      setUnreadNotifications(event.data);
  };

  useEffect(() => {
    if (user?.id && !socket.connected) {
      socket.io.opts.query = { id: user.id };
      socket.connect();
      socket.on(user.id, (payload: WebsocketEventPayload<boolean>) => {
        handleWebsocketEvents(payload);
      });
    } else if (!user?.id && socket.connected) {
      socket.disconnect();
    }
  }, [user.id]);

  const signIn = useCallback((userObj: CurrentUser, tokenStr: string) => {
    setStorageItem(LOCAL_STORAGE_USER, userObj);
    setStorageItem(LOCAL_STORAGE_ACCESS_TOKEN, tokenStr);
    setUser(userObj);
    setToken(tokenStr);
  }, []);

  const signOut = useCallback(() => {
    removeStorageItem(LOCAL_STORAGE_USER);
    removeStorageItem(LOCAL_STORAGE_ACCESS_TOKEN);
    setUser(defaultUser);
    setToken('');
  }, []);

  const updateUser = useCallback((userObj: CurrentUser) => {
    setStorageItem(LOCAL_STORAGE_USER, userObj);
    setUser(userObj);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        unreadNotifications,
        token,
        buArray: user.buArray,
        signed: Boolean(user),
        updateUser,
        setUnreadNotifications,
        signIn,
        signOut,
        isEmailFlagReady,
        isCookieIdFlagReady,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
};

export { AuthProvider, useAuth };
