import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { NetworkAccountFormData } from '@/modules/register/preRegister/steps/NetworkAccountForm/NetworkAccountForm.types';

export type RegisterFormsData = {
  preRegistrationId: string;
  preRegister: {
    name: string;
    email: string;
    cpf: string;
    emailConfirmation: string;
    phoneNumber: string;
    isPolicyTerms: boolean;
    isMarketingTerms: boolean;
  };
  influencer: {
    username: string;
    password: string;
    comparePassword: string;
    email: string;
    cpf: string;
    birth: string;
    gender: { id?: string; value: string; label: string };
    rg: string;
  };
  influencerAddress: {
    cep: string;
    address: string;
    number: string;
    complement: string;
    state: { id?: string; value: string; label: string };
    city: { id?: string; value: string; label: string };
    neighborhood: string;
  };
  companyAddress: {
    companyName: string;
    cnpj: string;
    hasCompany: boolean;
    cep: string;
    address: string;
    number: string;
    complement: string;
    state: { id?: string; value: string; label: string };
    city: { id?: string; value: string; label: string };
    neighborhood: string;
  };
  bankInfo: {
    bank: {
      value: string;
      label: string;
    };
    agency: string;
    account: string;
    accountType: string;
  };
  preRegisterNetworks: {
    networkAccounts: NetworkAccountFormData[];
  };
  userCpf: string;
  cpfValidation: {
    isNewRegistration: boolean;
    isWaiting: boolean;
    influencerActive: {
      isInfluencerActiveSameBU: boolean;
      otherBu: string;
    };
    isInfluencerRejected: boolean;
    pending: {
      type: '' | 'PreRegistration' | 'Registration';
      preRegistrationId: string;
      buFirst: string;
      socialNetworks: [];
    };
  };
  proofFileUrl: string;
};

type RegisterContextData = {
  registerValues: RegisterFormsData;
  setRegisterPartialValues: (values: Partial<RegisterFormsData>) => void;
};

export type RegisterProviderProps = {
  children: ReactNode;
};

export const initialState: RegisterFormsData = {
  preRegistrationId: '',
  preRegister: {
    name: '',
    email: '',
    cpf: '',
    emailConfirmation: '',
    phoneNumber: '',
    isPolicyTerms: false,
    isMarketingTerms: false,
  },
  influencer: {
    username: '',
    password: '',
    comparePassword: '',
    email: '',
    cpf: '',
    birth: '',
    gender: { id: '', value: '', label: '' },
    rg: '',
  },
  influencerAddress: {
    cep: '',
    address: '',
    number: '',
    complement: '',
    state: { id: '', value: '', label: '' },
    city: { id: '', value: '', label: '' },
    neighborhood: '',
  },
  companyAddress: {
    companyName: '',
    cnpj: '',
    hasCompany: false,
    cep: '',
    address: '',
    number: '',
    complement: '',
    state: { id: '', value: '', label: '' },
    city: { id: '', value: '', label: '' },
    neighborhood: '',
  },
  bankInfo: {
    bank: {
      value: '',
      label: '',
    },
    agency: '',
    account: '',
    accountType: '',
  },
  preRegisterNetworks: { networkAccounts: [{ network: '', url: '' }] },
  userCpf: '',
  cpfValidation: {
    isNewRegistration: true,
    isWaiting: false,
    influencerActive: {
      isInfluencerActiveSameBU: false,
      otherBu: '',
    },
    isInfluencerRejected: false,
    pending: {
      type: '',
      preRegistrationId: '',
      buFirst: '',
      socialNetworks: [],
    },
  },
  proofFileUrl: '',
};

export const RegisterContext = createContext({
  registerValues: initialState,
} as RegisterContextData);

function RegisterProvider({ children }: RegisterProviderProps) {
  const [registerValues, setRegisterValues] = useState(initialState);

  const handleRegister: RegisterContextData['setRegisterPartialValues'] =
    useCallback((values) => {
      setRegisterValues((prevState) => ({
        ...prevState,
        ...values,
      }));
    }, []);

  return (
    <RegisterContext.Provider
      value={{
        registerValues,
        setRegisterPartialValues: handleRegister,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
}

export const useRegister = () => {
  return useContext(RegisterContext);
};

export default RegisterProvider;
