import FlagProvider, { UnleashClient } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { UNLEASH_CONFIG } from '@/config/unleash';

type Props = {
  children: React.ReactNode;
};

export const UnleashProvider = ({ children }: Props) => {
  const client = new UnleashClient(UNLEASH_CONFIG);

  useEffect(() => {
    const asyncProcess = async () => {
      await client.start();
    };
    asyncProcess();
    // eslint-disable-next-line
  }, []);

  return (
    <FlagProvider unleashClient={client} startClient={false}>
      {children}
    </FlagProvider>
  );
};
