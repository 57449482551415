import { io } from 'socket.io-client';
import { getCoreConfig } from '@/common/config';
const { publicRuntimeConfig } = getCoreConfig();

const getBaseUrl = () => {
  if (publicRuntimeConfig.publicApi.base.includes('.com.br'))
    return publicRuntimeConfig.publicApi.base.split('.com.br')[0] + '.com.br';
  return publicRuntimeConfig.publicApi.base;
};
const getPath = (): string => {
  const pathname = publicRuntimeConfig.publicApi.base.split('.com.br')[1];
  if (!pathname) return '/socket.io';
  const trimmedPath = pathname.trim().replaceAll('/', '');
  const path = `/${trimmedPath ? trimmedPath + '/' : ''}socket.io`;
  return path;
};

export const socket = io(getBaseUrl(), {
  path: getPath(),
  transports: ['websocket'],
  autoConnect: false,
});
