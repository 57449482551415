import { SnackBar, ThemeProvider } from '@grupo-sbf/motriz-core';
import sbfTheme from '@grupo-sbf/motriz-tokens/themes/sbf';
import { PartialKeys } from '@grupo-sbf/motriz-utils';
import type { NextPageWithLayout } from 'next';
import Head from 'next/head';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { SWRConfig } from 'swr';
import AppSeo from '@/common/components/AppSeo/AppSeo';
import { GlobalStyle } from '@/common/components/GlobalStyle';
import GtmScripts from '@/common/components/GtmScripts/GtmScripts';
import Preconnect from '@/common/components/Preconnect';
import OnePixel from '@/common/components/Security/ThirdParty/OnePixel';
import { UnleashProvider } from '@/common/components/UnleashProvider';
import { getCoreConfig } from '@/common/config';
import { BreakpointServerType } from '@/common/constants/breakpoints';
import { AuthProvider } from '@/common/contexts/AuthContext';
import RegisterProvider from '@/common/contexts/RegisterContext/RegisterContext';
import {
  BreakpointProvider,
  BreakpointProviderProps,
} from '@/hooks/use-breakpoint';
import MainLayout from '@/layout/MainLayout';
const PRECONNECT_URLS = [
  '//www.googletagmanager.com',
  '//www.google-analytics.com',
];

export type MotrizProviderPropsType = PartialKeys<
  BreakpointProviderProps,
  'initialBreakpoint'
>;

type CustomAppProps = {
  Component: NextPageWithLayout;
  pageProps: {
    session: Session;
  };
} & MotrizProviderPropsType;

const {
  publicRuntimeConfig: { gtmID },
} = getCoreConfig();

function CustomApp({
  Component,
  pageProps,
  initialBreakpoint,
}: CustomAppProps) {
  const { mainLayoutProps } = Component;

  const getLayout =
    Component.getLayout ||
    ((page) => <MainLayout {...mainLayoutProps}>{page}</MainLayout>);

  const componentLayout = getLayout(<Component {...pageProps} />);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, maximum-scale=1, minimum-scale=1, initial-scale=1, user-scalable=no, shrink-to-fit=no"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <Preconnect urlsList={PRECONNECT_URLS} />
      </Head>
      <SWRConfig
        value={{
          provider: () => new Map(),
          revalidateIfStale: false,
          revalidateOnFocus: false,
        }}
      >
        <SessionProvider session={pageProps.session}>
          <AuthProvider>
            <UnleashProvider>
              <ThemeProvider theme={sbfTheme}>
                <RegisterProvider>
                  <BreakpointProvider
                    initialBreakpoint={
                      initialBreakpoint as BreakpointServerType
                    }
                  >
                    <GtmScripts gtmID={gtmID} />
                    <AppSeo />
                    <GlobalStyle />
                    <OnePixel />
                    {componentLayout}
                    <SnackBar />
                  </BreakpointProvider>
                </RegisterProvider>
              </ThemeProvider>
            </UnleashProvider>
          </AuthProvider>
        </SessionProvider>
      </SWRConfig>
    </>
  );
}

export default CustomApp;
