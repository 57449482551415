export const enum BREAKPOINTS_ENUM {
  XS = 'xsmall',
  SM = 'small',
  MD = 'medium',
  LG = 'large',
  XL = 'xlarge',
  '2XL' = '2xlarge',
}

export type BreakpointServerType =
  | BREAKPOINTS_ENUM.SM
  | BREAKPOINTS_ENUM.MD
  | BREAKPOINTS_ENUM.LG;

export const breakpoints = {
  xs: '0',
  sm: '420px', // 26.25em
  md: '720px', // 45em
  lg: '1024px', // 64em
  xl: '1366px', // 85.375em
};

export const serverBreakpoints: readonly BreakpointServerType[] = [
  BREAKPOINTS_ENUM.SM,
  BREAKPOINTS_ENUM.MD,
  BREAKPOINTS_ENUM.LG,
] as const;
