import { SocialProfileJsonLd } from 'next-seo';

const SocialProfileJson = () => {
  return (
    <SocialProfileJsonLd
      type="Organization"
      name="Grupo SBF"
      url="https://gruposbf.com.br"
      sameAs={[
        'https://www.facebook.com/gruposbf',
        'https://www.youtube.com/user/gruposbf',
        'https://www.instagram.com/gruposbf/',
        'https://www.twitter.com/gruposbf/',
      ]}
    />
  );
};

export default SocialProfileJson;
